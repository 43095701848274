<template>
  <div ref="modal" class="modal fade" :class="size" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="modalTitle" class="modal-title">
            {{ modalTitle }}
          </h5>
          <Icon @click="close" class="modal-close" icon="material-symbols-light:close-small-outline" data-dismiss="modal" />
        </div>
        <div class="modal-body">
          <slot v-if="isOpen" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  size: {
    type: String,
    default: "normal",
  },
});

const emit = defineEmits([ "close" ]);
const { $bootstrap } = useNuxtApp();
const modal = ref({});
const modalTitle = ref("");
const isOpen = ref(false);
let modalObject = null;

modalTitle.value = props.title;



defineExpose({
  open: () => {
    isOpen.value = true;
    modalObject.toggle();
  },
  close: () => {
    emit("close");
    modalObject.hide();
  },
  setTitle: (_title) => {
    modalTitle.value = _title;
  },
  getModalObject: () => {
    return modalObject;
  },
});

onMounted(() => {
  watch (props, () => {
    modalTitle.value = props.title;
  });
  modalObject = new $bootstrap.Modal(modal.value);
});
function close() { emit("close"); modalObject.hide(); }
</script>
